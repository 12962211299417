.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 700;
  background-color: var(--bgDark);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  width: 100px;
  height: 100px;
  background-color: var(--bgLight);
  border-radius: 10px;
  z-index: 701;
  box-shadow: 0px 0px 70px rgba(43, 89, 255, 0.451);
}

.rectangle {
  height: 30px;
  width: 13.2px;
  background-color: var(--buttonBackground);
  animation: rotate 2s linear infinite;
  z-index: 702;
}

@keyframes rotate {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  33% {
    transform: translateY(20px) rotate(120deg);
  }

  66% {
    transform: translateY(0) rotate(240deg);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}