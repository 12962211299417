:root {
  --bgDark: #050505;
  --bgLight: #E5EFFF;
  --colorPrimary: #1e2732;
  --colorPrimaryHover: #312c2d;
  --colorLight: #434343;
  --colorDangerHover: #b5202f;
  --colorDanger: #dc3545;
  --font-primary: 'Oxygen', sans-serif;
  --font-chat: 'Oxygen', sans-serif;
  --font-ifm: 'Lato', sans-serif;
  --colorBorder: #9595958b;
  --colorCta: #e9a70c;
  --buttonBackground: #443dff;
  --buttonHover: #726dff;
  --lightBlue: #6059da;

  /* new color names */

  /* main bg */
  --extremeBlack: #111111;
  --extremeWhite: #E5EFFF;
  /* buttons and other main things color */
  --primaryBlue: #443dff;
  /* used in box shadow */
  --secondaryBlue: #433dff77;
  --tertiaryBlue: #726dff;
  --lightGray: #9595958b;
  --darkGray: #2a2a2a;
  --extremeBlue: #1e2732;
  --textFieldBackground: rgba(67, 61, 255, 0.4);

  --darkGray: #2a2a2a;
}

body {
  background-color: var(--colorPrimary);
}

.abcd {
  background-color: #e6e6e6;

}

*:disabled {
  cursor: not-allowed;
}

.italic {
  font-style: italic;
}

#info {
  font-size: 12px;
  color: #808080;
}

.infoproblemstatement {
  font-size: 14px;
  color: #700202;
}

.info {
  font-size: 14px;
  color: var(--bgLight);
  font-family: var(--font-primary);
}

.info-datasets {
  font-size: 11px;
  font-family: var(--font-primary);
  line-height: 1px;
  color: #808080;
}

#inputs-hr {
  width: 100%;
  background-color: var(--buttonBackground);
}

.minified {
  height: 20px;
}

.margin {
  margin: 0px 20px;

}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.snackbar-button {
  background-color: var(--buttonBackground);
  padding: 4px 10px;
  border: 1px solid var(--buttonBackground);
  cursor: pointer;
  border-radius: 5px;
  color: var(--bgLight);
}

.snackbar-button:hover {
  background-color: var(--buttonHover);
  border: 1px solid var(--buttonHover);
}

/* {
  --bgDark: #f7eacc;
  --bgLight: #FAF5EA;
  --colorPrimary: #050505;
  --colorLight: rgb(67, 67, 67);
  --font-primary: 'Oxygen', sans-serif;
  --bgNav: #0d0d0d;
  --backgroundColor: #050505;
  --buttonHover: #726dff;
  --colorSilver: #E5EFFF;
  --backgroundVideo: #060512;
} */

/* Apply these styles to the table element */
/* table {
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: scroll;
  font-family: var(--font-primary);
  margin-bottom: 20px;
color: white;
padding: 0;
}*/


/* Apply these styles to the table header cells (<th>) */
/* th {
  background-color: var(--buttonHover);
  color: #ffffff;
  font-weight: bold;
  padding: 15px 5px;
  text-align: left;

} */

/* Apply these styles to the table data cells (<td>) */
/* td {
  padding: 15px 5px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  color: white;

} */

/* Apply hover effect to rows */
/* tr:hover {
  background-color: var(--buttonBackground);
} */

/* Remove the border on the last row (optional) */
/* tr:last-child td {
  border-bottom: none;
} */